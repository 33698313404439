<script>
export default {
  components: {},
  props: {
    placeholder: String,
    text: String,
    value: String,
    list: Array,
  },
  model: {
    prop: 'value'
  },
  data() {
    return {
      isLoading: false,
      newValue: "",
    };
  },
  created() {
  },
  mounted() {
    this.newValue = this.value;
  },
  computed: {},
  watch: {
    'newValue'() {
      this.$emit("input", this.newValue)
    }
  },
  methods: {},
};
</script>
<template>
  <b-form-group style="position: relative">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#f1b44c"
      :width="70"
      :height="70"
      loader="dots"
      :is-full-page="false"
    />
    <label>{{ placeholder }}</label>
    <b-form-input
      type="text"
      multiple
      :id="$attrs.id + '-input'"
      v-model="newValue"
      :list="$attrs.id + '-list'"
      :placeholder="placeholder"
    />
    <b-form-datalist
      :id="$attrs.id + '-list'"
      :options="list"
    ></b-form-datalist>
  </b-form-group>
</template>
