export const menuItems = [
    {
      id: null,
      label: "menuitems.mycalendar.text",
      icon: "bx-calendar",
      link: "/",
      meta: {
        authRequired: true
      },     
    },
    {
      id: null,
      label: "menuitems.workOrders.text",
      icon: "bx bx-grid-horizontal",    
      link: "/workOrders",
      meta: {
        permissionsRequired: "accessWorkOrdersListPage"
      },      
    },     
    {
      id: null,
      label: "menuitems.capacitiesDashboard.text",
      icon: "bx bx-stats ",
      meta: {
        permissionsRequired: "accessCapacityDashboard"
      },       
      link: "/dashboard/capacities"
    },        
    {
      id: null,
      label: "menuitems.maestro.text",
      icon: "bx-list-ol",
      meta: {
        keyOption: "Maestro",
      },       
      link: "/maestro"
    },       
    {
        id: 10,
        label: "menuitems.configuration.text",
        icon: "bx-cog",
        meta: {
          authRequired: true,
          rolesRequired: "admin"
        },        
        subItems: [        
          {
            id: 9,
            label: "general.users",
            link: "/configuration/users",
            parentId: 10
          },    
          {
            id: 14,
            label: "menuitems.configuration.list.progress",
            link: "/configuration/progress",
            parentId: 10
          },      
          {
            id: 17,
            label: "menuitems.configuration.list.JobTypes",
            link: "/configuration/jobTypes",
            parentId: 10
          },   
          {
            id: 18,
            label: "menuitems.configuration.list.contractors",
            link: "/configuration/contractors",
            parentId: 10
          },   
          {
            id: 19,
            label: "menuitems.configuration.list.projectmanagers",
            link: "/configuration/projectmanagers",
            parentId: 10
          },  
          {
            id: 20,
            label: "menuitems.configuration.list.projectenvironments",
            link: "/configuration/environments",
            parentId: 10
          },    
          {
            id: 12,
            label: "menuitems.configuration.list.templates",
            link: "/configuration/templates",
            parentId: 10
          },   
          {
            id: 15,
            label: "menuitems.configuration.list.Installers",
            link: "/configuration/installers",
            parentId: 10
          },  
          {
            id: 16,
            label: "menuitems.configuration.list.Shippers",
            link: "/configuration/shippers",
            parentId: 10
          },                                            
          {
            id: 11,
            label: "menuitems.configuration.list.emails",
            link: "/configuration/emails",
            parentId: 10
          },             
          {
            id: 16,
            label: "menuitems.configuration.list.Maestro",
            link: "/configuration/maestro/",
            meta: {
              keyOption: "Maestro",
            },
            parentId: 10
          }          
        ]
    }
];

