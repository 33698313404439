import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';
import VueRouter from 'vue-router';
import vco from 'v-click-outside';
import router from './router/index';
import Scrollspy from 'vue2-scrollspy';
import VueSweetalert2 from 'vue-sweetalert2';
import axios from 'axios';
import '@/design/index.scss';
import store from '@/state/store';
import App from './App.vue';
import Dayjs from 'dayjs';
import MsalBrowserUtil from '@/utils/msalBrowserUtil';
import MsalBrowserUtilDemo from '@/utils/msalBrowserUtilDemo';
import Loading from 'vue-loading-overlay';
import Layout from "@/router/layouts/main";
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue2-datepicker/index.css";
import Multiselect from "vue-multiselect";
import Store from '@/state/store'
import VueAppInsights from "@/utils/app-insights";


import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import LocaleData from 'dayjs/plugin/localeData';
// eslint-disable-next-line no-unused-vars
import EnLocale from 'dayjs/locale/en';
// eslint-disable-next-line no-unused-vars
import EnAuLocale from 'dayjs/locale/en-au';
// eslint-disable-next-line no-unused-vars
import EnCaLocale from 'dayjs/locale/en-ca';
// eslint-disable-next-line no-unused-vars
import FrLocale from 'dayjs/locale/fr';
// eslint-disable-next-line no-unused-vars
import FrCaLocale from 'dayjs/locale/fr-ca';
// eslint-disable-next-line no-unused-vars
import EsLocale from 'dayjs/locale/es';

import DocumentationPlugin from "vue-common/src/components/documentation-component";
import AppConfig from "./app.config.json";

Vue.use(DocumentationPlugin, {
  dropboxOptions: {
    base_url: 'https://api.dropboxapi.com/2/',
    clientId: AppConfig.dropbox_client_id,
    base_path: "/Apps/web-cab/"
  },
  googleDriveOptions: {
    gapi_url: 'https://apis.google.com/js/api.js',
    gsi_url: 'https://accounts.google.com/gsi/client',
    baseUrl: 'https://www.googleapis.com',
    scopes: 'https://www.googleapis.com/auth/drive.file',
    clientId: AppConfig.google_drive_client_id,
    apiKey: AppConfig.google_drive_api_key,
    discoveryDoc: 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    redirect_uri: window.location.origin,
    appFolderName: "web-cab-doc",
    requestOrigin: 'Planning',
  },
  oneDriveOptions:{
      clientId: AppConfig.documentation_client_id,
      requestOrigin: 'Planning',
      appFolderName: "web-cab-doc",
      graphUrl: 'https://graph.microsoft.com/v1.0/'
  },
  organisationApiUrl: AppConfig.organisation_api,
  getTokenFunction: async () => {
    return await Vue.prototype.$msal.getOrganisationApiToken();
  }
})

import MsalPlugin from './services/msal-service';

Vue.use(MsalPlugin, {
  msalConfig: {
    auth: {
      clientId: AppConfig.client_id,
      authority: "https://webcab.b2clogin.com/webcab.onmicrosoft.com/B2C_1_Signin",
      knownAuthorities: ["webcab.b2clogin.com"],
      redirectUri: window.location.origin
    },
    cache: {
      cacheLocation: 'sessionStorage',
    },
    scopes: ["https://webcab.onmicrosoft.com/a4a4be7a-fee8-492c-8474-dffa0d6252c0/rw"]
  },
  password_authority: 'https://webcab.b2clogin.com/tfp/webcab.onmicrosoft.com/B2C_1_PasswordReset'
});

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
var isBetween = require('dayjs/plugin/isBetween');
Dayjs.extend(isSameOrBefore);
Dayjs.extend(isSameOrAfter);
Dayjs.extend(isBetween);

let redirectUrl = window.location.origin;

let msalBrowserUtil = null;

if (process.env.NODE_ENV === 'demo') {
  msalBrowserUtil = new MsalBrowserUtilDemo(redirectUrl, AppConfig.client_id);
} else {
  msalBrowserUtil = new MsalBrowserUtil(redirectUrl, AppConfig.client_id);
}
//msalBrowserUtil.init();
Vue.prototype.$msal = msalBrowserUtil;

import JQuery from 'jquery';
window.$ = JQuery;

import i18n from './i18n';

import tinymce from 'vue-tinymce-editor';
Vue.component('tinymce', tinymce);

export const EventBus = new Vue();
Vue.prototype.$bus = EventBus;

Dayjs.extend(LocalizedFormat);
Dayjs.extend(LocaleData);
Dayjs.locale(i18n.localeComplete);
Vue.prototype.$dayjs = Dayjs;
let config = {
  withCredentials: true,
  baseURL: AppConfig.api
};
var verifTokenExpiration = async (config) => {
  if (process.env.NODE_ENV === 'demo') {
    return config;
  } else {
    let currentAccount = Vue.prototype.$msal.currentAccount;
    if (currentAccount != null) {
      const token = await Vue.prototype.$msal.getToken();
      if (token) {
        Object.assign(config, {
          headers: { authorization: 'Bearer ' + token },
        });
      }
      return config;
    }
  }
};

let axiosMainClient = axios.create(config);

axiosMainClient.interceptors.request.use(verifTokenExpiration, (error) =>
  Promise.reject(error)
);
axiosMainClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response != undefined && error.response.status === 403) {
      router.replace('/403');
    }
    return Promise.reject(error);
  }
);
Vue.prototype.$axiosMainClient = axiosMainClient;

Vue.use(VueRouter);

Vue.use(vco);
Vue.use(Scrollspy);
const VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(require('vue-chartist'));
Vue.use(VueSweetalert2);
Vue.use(VueAppInsights, "PlanificationUI", {
  instrumentationKey: AppConfig,
  disableAjaxTracking: true,
  disableFetchTracking: true,
  disableExceptionTracking: true
});
Vue.component('apexchart', VueApexCharts);
Vue.component('loading', Loading);
Vue.component('Layout', Layout);
Vue.component('multiselect', Multiselect)
Vue.mixin({
  computed: {
    measureTypes() {
      return Store.state.calendar.measureTypes
    },
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');